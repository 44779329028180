import "angular";
import * as _ from "lodash";
import "angular-socialshare";
import "angular-cache";
import "moment";
import "angular-moment";
import "ng-country-select/dist/ng-country-select";
import "angular-http-auth";
import "angular-animate";
import "angular-aria";
import "angular-ui-bootstrap";
import "angular-bootstrap-show-errors";
import "angular-aside";
import "angular-cookies";
import "ng-file-upload";
import "ng-magnify/src/js/ng-magnify";
import "ngmap/build/scripts/ng-map";
import "angular-promise-extras";
import "angular-resource";
import "angular-sanitize";
import "ng-youtube-embed";
import "satellizer";
import "angular-toastr";
import "ui-select";
import "angular-update-meta";
import "angular-validation-match";
import "lazysizes/plugins/attrchange/ls.attrchange.js";
import "lazysizes/plugins/respimg/ls.respimg.js";
import "lazysizes";
import "ui-cropper";
import "@uirouter/angularjs";
import "angular-ui-router-tabs";
import { StickyStatesPlugin } from "@uirouter/sticky-states";
import "angular-permission/dist/angular-permission.js";
import "angular-permission/dist/angular-permission-ui.js";
import * as Headroom from "headroom.js/dist/headroom";
import "headroom.js/dist/angular.headroom";
import * as braintreeClient from "braintree-web/client";
import * as braintreeHostedFields from "braintree-web/hosted-fields";
import "@webcomponents/url";
import "url-search-params";
import * as Raven from "raven-js";
import * as AngularPlugin from "raven-js/dist/plugins/angular";

Raven.config("https://34d4ae4eb2a641f0a55838336bcafe24@sentry.io/245290", {
  release: process.env.SENTRY_RELEASE,
  ignoreErrors: [
    "Possibly unhandled rejection:",
    /ReferenceError:.*/,
    // Random plugins/extensions
    "top.GLOBALS",
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    "originalCreateNotification",
    "canvas.contentDocument",
    "MyApp_RemoveAllHighlights",
    "http://tt.epicplay.com",
    "Can't find variable: ZiteReader",
    "jigsaw is not defined",
    "ComboSearch is not defined",
    "http://loading.retry.widdit.com/",
    "atomicFindClose",
    // Facebook borked
    "fb_xd_fragment",
    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
    // reduce this. (thanks @acdha)
    // See http://stackoverflow.com/questions/4113268
    "bmi_SafeAddOnload",
    "EBCallBackMessageReceived",
    // See
    // https://groups.google.com/a/chromium.org/forum/#!topic/chromium-discuss/7VU0_VvC7mE
    "_gCrWeb",
    // See http://toolbar.conduit.com/Debveloper/HtmlAndGadget/Methods/JSInjection.aspx
    "conduitPage",
    // Google Search app (iOS)
    // See: https://github.com/getsentry/raven-js/issues/756
    "null is not an object (evaluating 'elt.parentNode')",
    // Dragon Web Extension from Nuance Communications
    // See: https://forum.sentry.io/t/error-in-raven-js-plugin-setsuspendstate/481/
    "plugin.setSuspendState is not a function",
    "Failed to fetch",
    "SnapTube",
    "window.onYouTubeIframeAPIReady",
  ],
  ignoreUrls: [
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Woopra flakiness
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    /cdn\.ampproject\.org/i
  ]
})
  .addPlugin(AngularPlugin)
  .install();

// Vendor styles
import "animate.css";
// import "bootstrap/dist/css/bootstrap.css";
// import "angular-aside/dist/css/angular-aside.css";
import "angular-toastr/dist/angular-toastr.css";
import "ui-select/dist/select.css";
import "ui-cropper/source/scss/ui-cropper.scss";
import "ng-magnify/src/css/ng-magnify.css";

/* eslint-disable angular/window-service */
declare global {
  interface Window {
    _: any;
    Headroom: any;
    braintreeClient: any;
    braintreeHostedFields: any;
    StickyStatesPlugin: any;
  }
}
window._ = window._ || _;
window.Headroom = window.Headroom || Headroom;
window.braintreeClient = window.braintreeClient || braintreeClient;
window.braintreeHostedFields = window.braintreeHostedFields || braintreeHostedFields;
window.StickyStatesPlugin = window.StickyStatesPlugin || StickyStatesPlugin;
/* eslint-enable */
